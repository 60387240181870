<template>
    <div>
        <c-table-dashboard-critique :title="title" :items="getItems" :columns="columns" :totalRows="totalRows" :perPage="perPage"
            :currentPage="currentPage" :api="api" @changeCurrentPage="changeCurrentPage"
            @changePerPage="changePerPage" />
    </div>
</template>
  
<script>
import {
    BCard,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdownItem,
    BDropdown,
    BRow,
    BCol,
} from "bootstrap-vue";
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";
import table from "@/libs/table";
import vSelect from "vue-select";
import CTableDashboardCritique from "@/components/CTableDashboardCritique.vue";

export default {
    components: {
        BCard,
        BAvatar,
        BBadge,
        BPagination,
        BFormGroup,
        BFormInput,
        BFormSelect,
        BDropdownItem,
        BDropdown,
        BRow,
        BCol,
        vSelect,
        CTableDashboardCritique
    },
    data() {
        const permission = this.$store.getters["app/ACTIONS"];
        return {
            bar_key: 0,
            table_key: 0,
            title: "Kritik & Saran",
            api: `input`,
            items: [],
            totalRows: 0,
            perPage: 10,
            limit: 0,
            orderBy: "createdAt",
            sortBy: "desc",
            currentPage: 1,
            columns: [
                { key: "no", label: "#", sortable: false },
                { key: "name", label: "Nama Pelapor", sortable: true },
                { key: "createdAt", label: "Tanggal", sortable: true },
                { key: "inputTarget", label: "Ditujukan", sortable: true },
                { key: "type", label: "Tipe Masukan", sortable: true },
                {
                    key: "action",
                    label: "aksi",
                    class: "text-center",
                    actions: { show: Boolean(permission.show) },
                },
            ],
        };
    },
    async mounted() {
        const _ = this;
    },
    methods: {
        async getData() {
            const _ = this;
            let data;
            await _.$axios.get(_.graphic_api, { params: this.filter }).then((res) => {
                data = res.data.data;
            });
            return data;
        },
        getItems(ctx, callback) {
            table.getItems(ctx, callback, this);
        },
        changeCurrentPage(page) {
            table.changeCurrentPage(this, page);
        },
        changePerPage(perPage) {
            table.changePerPage(this, perPage);
        },
    },
};
</script>
  