<template>
    <div>
        <b-card>
            <b-card-body>
                <h3>{{ title }}</h3>
            </b-card-body>
            <b-row class="mb-0">
                <b-col>
                    <b-form-group>
                        <b-form-datepicker :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                            locale="id" v-model="filter.startDate" reset-button />
                    </b-form-group>
                </b-col>
                <b-col>
                    <b-form-group>
                        <b-form-datepicker :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                            locale="id" :min="filter.startDate" v-model="filter.endDate" reset-button />
                    </b-form-group>
                </b-col>
                <b-col>
                    <b-form-group>
                        <v-select v-model="filter.target" :options="targets" :reduce="(item) => item.id"
                            placeholder="Ditujukan" label="name" @input="handleChange" />
                    </b-form-group>
                </b-col>
                <b-col>
                    <b-form-group>
                        <v-select v-model="filter.type" :options="types" :reduce="(item) => item.value"
                            placeholder="Tipe Masukan" label="name" @input="handleChange" />
                    </b-form-group>
                </b-col>
            </b-row>

            <b-table striped hover responsive class="position-relative" :per-page="perPage" :current-page="currentPage"
                :items="items" :fields="columnOptions" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection" :filter="filter" :filter-included-fields="filterOn"
                @filtered="onFiltered" ref="cTableStudyPlan" show-empty>
                <template #empty style="text-align: center">
                    Tidak ada data
                </template>

                <template #cell(no)="data">
                    {{ (currentPage - 1) * perPage + (data.index + 1) }}
                </template>
                <template #cell()="data">
                    <i>{{ data.value }}</i>
                </template>

                <template #cell(action)="data" class="text-center">
                    <div style="min-width: 270px">
                        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary"
                            v-b-popover.hover.bottom="'Detail'" size="sm" class="btn-icon float-sm-center mr-1"
                            v-if="actions.show" @click="onShow(data.item)">
                            <feather-icon icon="EyeIcon" />
                        </b-button>

                        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="info"
                            v-b-popover.hover.bottom="'Cetak'" size="sm" class="btn-icon float-sm-center mr-1"
                            v-if="actions.print" @click="onPrint(data.item.id)">
                            <feather-icon icon="PrinterIcon" />
                        </b-button>
                    </div>
                </template>
            </b-table>

            <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
                <b-form-group label="Per Page" label-cols="6" label-align="left" label-size="md"
                    label-for="sortBySelect" class="text-nowrap mb-md-0 mr-1">
                    <b-form-select id="perPageSelect" v-model="onTablePerPage" @change="changePerPage" size="md" inline
                        :options="pageOptions" />
                </b-form-group>

                <div>
                    <b-pagination v-model="onTableCurrentPage" :total-rows="totalRows" :per-page="perPage" first-number
                        prev-class="prev-item" next-class="next-item" last-number class="mb-0">
                        <template #prev-text>
                            <feather-icon icon="ChevronLeftIcon" size="18" />
                        </template>
                        <template #next-text>
                            <feather-icon icon="ChevronRightIcon" size="18" />
                        </template>
                    </b-pagination>
                </div>
            </b-card-body>
        </b-card>

        <b-modal ref="detail" title="Detail Kritik & Saran" ok-only ok-title="Kembali">
            <div v-if="suggestions.length != 0">
                <b-form-group label="Nama Pelapor" label-for="h-place" label-cols-md="2">
                    <b-form-input id="h-place" placeholder="Perihal" :value="suggestions.pelapor" readonly />
                </b-form-group>
                <b-form-group label="Ditujukan" label-for="h-place" label-cols-md="2">
                    <b-form-input id="h-place" placeholder="Ditujukan" :value="suggestions.inputTarget.name" readonly />
                </b-form-group>
                <b-form-group label="Tipe Masukan" label-for="h-place" label-cols-md="2">
                    <b-form-input id="h-place" placeholder="Tipe Masukan" :value="suggestions.type" readonly />
                </b-form-group>
                <b-form-textarea id="textarea-default" :value="suggestions.value" placeholder="Kritik & Saran" rows="3" readonly/>
            </div>
        </b-modal>
    </div>
</template>
  
<script>
import {
    BCard,
    BTable,
    BAvatar,
    BBadge,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroupPrepend,
    BButton,
    BCardBody,
    VBPopover,
    VBModal,
    BModal,
    BAlert,
    BFormFile,
    BRow,
    BCol,
    BFormDatepicker,
    BFormTextarea,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import vSelect from "vue-select";

export default {
    components: {
        BTable,
        BAvatar,
        BBadge,
        BFormGroup,
        BFormSelect,
        BPagination,
        BInputGroup,
        BFormInput,
        BInputGroupAppend,
        BInputGroupPrepend,
        BButton,
        BCardBody,
        BCard,
        Ripple,
        VBPopover,
        VBModal,
        BModal,
        BAlert,
        BFormFile,
        ToastificationContent,
        BRow,
        BCol,
        vSelect,
        BFormDatepicker,
        BFormTextarea
    },
    directives: {
        Ripple,
        "b-popover": VBPopover,
        "b-modal": VBModal,
    },
    props: {
        title: {
            type: String,
            required: true,
        },
        items: {
            type: Function,
            required: true,
        },
        columns: {
            type: Array,
            required: true,
            default: () => [],
        },
        totalRows: {
            type: Number,
            required: true,
            default: () => 0,
        },
        currentPage: {
            type: Number,
            required: true,
            default: () => 0,
        },
        perPage: {
            type: Number,
            required: true,
            default: () => 0,
        },
        api: {
            type: String,
            required: true,
        },
        apiDelete: {
            type: String,
        },
        apiImport: {
            type: String,
        },
        add: {
            type: Boolean,
        },
        import: {
            type: Boolean,
        },
        export: {
            type: Boolean,
        },
        exportFile: {
            type: String,
        },
    },
    data() {
        return {
            pageOptions: [10, 25, 50],
            onTablePerPage: this.$props.perPage,
            onTableCurrentPage: this.$props.currentPage,
            sortBy: "createdAt",
            sortDesc: true,
            sortDirection: "desc",
            filter: {
                startDate: null,
                endDate: null,
                target: null,
                type: null,
            },
            filterOn: [],
            infoModal: {
                id: "info-modal",
                title: "",
                content: "",
            },
            actions: {
                add: Boolean(this.$props.add),
                import: Boolean(this.$props.import),
                export: Boolean(this.$props.export),
                show: true,
                edit: false,
                destory: false,
                print: false,
            },
            file: null,
            fileState: null,
            targets: [],
            types: [
                {
                    name: "Semua",
                    value: null,
                },
                {
                    name: "Komplain",
                    value: "komplain",
                },
                {
                    name: "Masukan",
                    value: "masukan",
                },
            ],
            suggestions: [],
            modalApi: 'input',
        };
    },
    computed: {
        sortOptions() {
            return this.columns
                .filter((f) => f.sortable)
                .map((f) => ({ text: f.label, value: f.key }));
        },
        columnOptions() {
            return this.columns.map((column) => {
                if (column.actions) {
                    this.actions = { ...this.actions, ...column.actions };
                    delete column.actions;
                }

                return column;
            });
        },
    },
    created() {
        const _ = this;
        _.getTargets();
    },
    mounted() { },
    methods: {
        info(item, index, button) {
            this.infoModal.title = `Row index: ${index}`;
            this.infoModal.content = JSON.stringify(item, null, 2);
            this.$root.$emit("bv::show::modal", this.infoModal.id, button);
        },
        resetInfoModal() {
            this.infoModal.title = "";
            this.infoModal.content = "";
        },
        onFiltered(filteredItems) { },
        changePerPage() {
            this.$emit("changePerPage", this.onTablePerPage);
        },
        onShow(item) {
            const _ = this;
            _.$axios.get(`${_.modalApi}/${item.id}`).then((res) => {
                _.suggestions = res.data.data;
            })
            this.$refs["detail"].show();
        },
        onPrint() { },
        handleOkImport(bvModalEvt) {
            bvModalEvt.preventDefault();
            this.handleSubmitImport();
        },
        handleShowImport() {
            this.file = null;
            this.fileState = null;
        },
        checkFormValidityImport() {
            const valid = this.$refs.form.checkValidity();
            this.fileState = valid;
            return valid;
        },
        handleSubmitImport() {
            const _ = this;
            if (!_.checkFormValidityImport()) {
                return;
            }
            const formData = new FormData();
            formData.append("file", _.file, _.file.name);
            _.$axios.post(`${_.$props.apiImport}`, formData, {}).then((res) => {
                const consume = res.data;
                if (consume.statusCode === 201) {
                    _.toastNotification(
                        "success",
                        "BellIcon",
                        `Berhasil menambahkan data.`
                    );
                    _.$refs.cTableStudyPlan.refresh();
                }
            });
            _.$nextTick(() => {
                _.$bvModal.hide("modal-import");
            });
        },
        toastNotification(variant, icon, text) {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: "Pemberitahuan",
                    icon,
                    text,
                    variant,
                },
            });
        },
        getTargets() {
            const _ = this;
            _.$axios.get("input-target/list").then((res) => {
                _.targets = res.data.data
            });
        },
        async handleChange() {
            this.graphic = await this.getData();
            this.bar_key += 1;
        },
        detail(item) {

        },
    },
    watch: {
        onTableCurrentPage: function (newValue) {
            let _ = this;
            _.$emit("changeCurrentPage", newValue);
        },
        items: function (newValue) { },
    },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
  